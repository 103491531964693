<template>
    <v-container fluid  >
        <v-card flat>
            <v-card-text  class="px-0 py-0 my-0 mx-0">
                <v-row class="px-0 py-0 my-0 mx-0" v-if="loading">
                    <v-col cols="12" class="mb-0 pb-0">
                        <v-progress-linear :active="loading" indeterminate></v-progress-linear>

                        <v-skeleton-loader v-if="loading_skeleton"
                                           v-bind="attrs"
                                           type="list-item-two-line, list-item-two-line, list-item-two-line"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-0 px-0" sm="12" >
                        <yandex-map ref="map" v-if="showMap"
                                    :coords="coords"
                                    :show-all-markers="showAllMarkers"
                                    :zoom="zoom"
                                    @map-was-initialized="initHandler"
                                    >
                        </yandex-map>
                    </v-col>
                </v-row>


            </v-card-text>
        </v-card>

    </v-container>
</template>
<script>

    import {mapGetters} from "vuex";

    import {loadYmap, yandexMap} from 'vue-yandex-maps'


    export default {
        name: "Map",
        components: {
            yandexMap,
        },
        data() {
            return {
                loading: false,
                loading_skeleton: false,
                progress: 0,
                orderItems: [],
                orderItemsDo: [],
                routerItems: [],
                invoiceItems: [],


                attrs: {
                    class: 'mb-3',
                    boilerplate: true,
                    elevation: 2,
                },



                showAllMarkers: false,
                mapMarker: null,
                zoom: 12,
                ymaps: null,
                coordinates: [],
                showMap: false,


            };
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
            coords: {
                get: function () {
                    return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [0, 0]
                },
                set: function (val) {
                    this.coordinates = (val && val.length === 2) ? val : [0,0]
                }
            },
            mapSettings() {
                this.$yandex.lang = 'ru_RU'
                return this.$yandex
            },
        },
        async mounted() {
            this.language = this.languages[this.tab]
            this.loading_skeleton = true
            await this.getRouters()

            await this.ymapInit()
            this.loading_skeleton = false
        },

        methods: {

            async getRouters() {
                this.loading = true;
                var _this = this
                let params = {};

                if (this.lang) {
                    params.language = this.lang
                }
                await this.$http
                    .get("courier/map", {
                        params: params,
                    })
                    .then(res => {
                        this.orderItems = res.body.data
                        //this.orderItemsDo = res.body.data.invoices_do
                        //this.routerItems = res.body.data.routes
                    })
                    .catch(err => {
                        this.orderItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_routers'));
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    });
            },

            async ymapInit() {
                var _this = this
                if (window.ymaps) {
                    this.showMap = false
                    if (this.$refs.map && this.$refs.map.myMap) {
                        this.$refs.map.myMap.destroy()
                    }
                    document.getElementById("vue-yandex-maps").remove()
                    delete window.ymaps
                }
                await loadYmap({...this.mapSettings, debug: true})
                if (window.ymaps) {
                    window.ymaps.ready(function () {
                        _this.showMap = true
                    })
                }



            },
            async initHandler(e) {
                if (window.ymaps) {

                    await window.ymaps.ready()
                        .then(async() => {
                            if (this.coords && this.coords.length === 2 && this.coords[0]===0 && this.coords[1]===0) {
                            window.ymaps.geolocation.get({
                                provider: 'yandex',
                                mapStateAutoApply: true,
                                autoReverseGeocode: true
                            })
                                .then( result => {
                                        this.coordinates = result.geoObjects.get(0).geometry.getCoordinates();
                                        e.setCenter(this.coordinates);
                                    }
                                )
                                .catch(err => {
                                    console.log('Error: ' + err)
                                })
                              }
                        })
                }
                e.geoObjects.options.set('draggable', true)


                var objectManager = new window.ymaps.ObjectManager({
                    // Чтобы метки начали кластеризоваться, выставляем опцию.
                    clusterize: true,
                    // ObjectManager принимает те же опции, что и кластеризатор.
                    gridSize: 32,
                    clusterDisableClickZoom: true
                });

                // Чтобы задать опции одиночным объектам и кластерам,
                // обратимся к дочерним коллекциям ObjectManager.
                objectManager.objects.options.set('preset', 'islands#greenDotIcon');
                objectManager.clusters.options.set('preset', 'islands#greenClusterIcons');
                e.geoObjects.add(objectManager);
                objectManager.add(this.orderItems);
                console.log(this.orderItems);




            },
            setLanguage(val) {
                this.language = this.languages[val]
               // this.checkCreate()
            },


      }
    };

</script>
